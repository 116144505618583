<template>
  <Teleport to="body">
    <div :id="id" class="micromodal" :class="parentClass" aria-hidden="true">
      <!-- [2] -->
      <div
        class="micromodal__overlay"
        tabindex="-1"
        :class="overlayClass"
        @click.self="close"
      >
        <!-- [3] -->
        <div
          class="micromodal__container"
          :class="containerClass"
          role="dialog"
          aria-modal="true"
          :aria-labelledby="`${id}-title`"
          @click.stop
        >
          <div class="modal__content h-full" :class="bodyClass">
            <div :class="cardClass" class="flex h-full flex-col">
              <header v-if="$slots.title">
                <span :id="`${id}-title`" class="mr-8 text-2xl font-bold">
                  <slot name="title"></slot>
                </span>
                <!-- [4] -->
                <button aria-label="Close modal" @click.self="close"></button>
              </header>

              <div :id="`${id}-content`" class="flex flex-grow flex-col">
                <slot></slot>
                <button
                  v-if="showCloseButton"
                  class="absolute right-0 top-0 mr-4 mt-4"
                  @click="close"
                >
                  <IconClose
                    height="14"
                    width="14"
                    class="fill-current text-black"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { computed, watch } from "vue";
import { useSessionStorage } from "@vueuse/core";
import { useModal, anyOpenedModal } from "~/lib/modal";
import { LOCAL_STORAGE_ANY_OVERLAY } from "~/constants";
import IconClose from "~icons/hh-icons/icon-close";

interface Props {
  id: string;
  showCloseButton?: boolean;
  header?: boolean;
  containerClass?: string;
  wrapperClass?: string;
  contentClass?: string;
  bodyClass?: string;
  cardClass?: string;
  modelValue: string | boolean;
  overlayClass?: string;
  updateGlobalOverlay?: boolean;
  parentClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
  showCloseButton: true,
  header: true,
  containerClass: "",
  parentClass: "",
  wrapperClass: "flex flex-grow flex-col items-center justify-center",
  contentClass: "relative rounded h-full flex flex-col",
  bodyClass: "flex items-center justify-center overflow-y-auto",
  cardClass: "relative bg-white",
  overlayClass: "",
  updateGlobalOverlay: true,
});
const emits = defineEmits<{
  (event: "update:modelValue", value: boolean): void;
}>();
const anyOverlay = useSessionStorage(LOCAL_STORAGE_ANY_OVERLAY, false);
const hhModal = useModal();
const openedModals = computed(() => {
  return anyOpenedModal.value;
});

function close() {
  hhModal.close(props.id);
  emits("update:modelValue", false);
}

watch(
  () => props.modelValue,
  (newVal) => {
    if (newVal) {
      hhModal.open(props.id);
      return;
    }
    close();
  }
);

watch(openedModals, (newVal) => {
  if (newVal) {
    anyOverlay.value = true;
    return;
  }
  anyOverlay.value = false;
  close();
});

defineOptions({
  name: "HhModal",
  inheritAttrs: false,
});
</script>

<style lang="scss">
@supports (-webkit-hyphens: none) {
  .micromodal__container {
    padding-top: env(safe-area-inset-top);
  }
}
</style>
